
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');


html {
    scroll-behavior: smooth;
  }


.parrafoPrinc
{
    padding: 0 30px;
    font-size: 18px!important;
    font-family: 'Open Sans', sans-serif;
    text-transform: none;
    font-weight: 400;
}



.divContacto
{
    background:#ffffff;
  
    text-align: center;
    padding-top: 0px;
}
.divImagen
{
    background-image: url("https://lh3.googleusercontent.com/KOTDJn-fHFLpog1ZoVcruQlX12cFGtyIMJqJkDCsAJT41V1cMo9B6hNlp8bF5sQsg-5t6B3Q=w1080-h608-p-no-v0");
    background-size: 110vw 110vh;

  
}

.textoTitulo{
    font-weight: 700;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    text-align: center;
    font-size: 70px!important;
}
.todaPantalla
{
    height: 100vh !important;
}

.navegador ,.navegador a{

    background: white;
    color: #515151;
    text-transform: uppercase;
}

input{
    
    border: solid 1px white!important ;
    color: white;
    padding-left: 5px!important;
    width: 99%!important ;
    
}
textarea{
    resize: none;
    
    border: solid 1px white!important ;
    color: white;
    padding-left: 5px!important;
    width: 100%!important ;
   
    padding: 14px;
    height: 140px;
}

.tituloCentro{
    text-align: center;
}
.divImage{
   
  
    background-image: url('https://c1.wallpaperflare.com/preview/23/653/160/technology-equipment-responsive-web.jpg');
   
    background-size: 110vw 110vh;

}
@media only screen and (min-width: 993px){

.container {
    width: 92%;
}}




/*texto

texto 70 px
*/

.txt70{

    font-size: 11vw!important;
}

@media only screen and (min-width: 601px){

    .txt70{

        font-size: 70px!important;
        color:rgba(0,0,0,0.6)
    }

    .ulLista {
        margin: 40px 0 0 0 ;
    }
    .intro-linedos {
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        width: 750px;
        text-align: center;
        margin: 30vh auto 0  auto !important;
    }
}
    



/* linea de separacion  */

.intro-line {
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    width: 750px;
    text-align: center;
    margin: 50px auto 0  auto ;
}


.intro-linedos {
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    width: 750px;
    text-align: center;
    margin: 30vh auto 0  auto ;
}


@media (max-width:800px){

    .intro-linedos {
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        width: 750px;
        text-align: center;
        margin: 30vh auto 0  auto ;
    }

}


/* texto titulo blanco*/

.txtTituloBlanco{

    color:white;
    border-top: white solid 5px;
} 

/* texto blanco*/

.txtBlanco{

    color:white;
    text-align: center;
}

/* div white redondo*/

.divRedondo{
    position: relative;
    background: white;
    margin: auto;
    width: 152px;
    height: 152px;
    padding: 5rem;
    text-align: center;
    border-radius: 50%;
    color: black;
    margin-top: 40px;
    margin-bottom: 40px;
    
}

.redondoIcono
{
    position: absolute;
    font-size: 45px;
    top:50%;
    -webkit-transform: translate(-45%, -37%);
    transform: translate(-20px,-18px);
   
    
}


.divUl{
background: #1b1b1b;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 0;
}

.divPrice
{
    background: #252626;
    font-size: 42px;
    color: #fff;
    padding: 25px 0;
}


.spanPrice{
    font-size: 14px;
    color: #717171;
    text-transform: uppercase;
}
.tablaDescripcion {
    background: #424242;
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
    border-bottom: 1px solid #252626;
    padding: 14px 0;
}

.ulLista{

    margin: -50px 0 0 0;
}
.ulLista li{

    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}

.ulLista a{

    width: 35px;
    height: 35px;
    border-radius: 4px;
    font-size: 38px;
    opacity: 0.65;
}

.ulLista a:hover{

   
    opacity:1.25;   
}

.instagram {
    display: inline-block;
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 4px;
    color: #fff;
    font-size: 28px;
    line-height: 0px;
    vertical-align: middle;
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    box-shadow: 0px 3px 10px rgba(0,0,0,.25);
  }

  .logo{

    padding: 0px 25px !important;
    color: #d6d6d6!important;
    font-weight: 700;
  }

  .headline-lines{
    border-top: 1px solid #b6b6b6;
    border-bottom: 1px solid #b6b6b6;
   }

   .tituloNegro{
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    color: #434343;

   }
  
   .parrafoSecund{
    font-size: 14.5px;
    line-height: 1.6em;
    color: #606060;
    font-family: 'Open Sans', sans-serif;
   }


   .parrafoTer{
    text-align: center;
    font-size: 23.5px;
    line-height: 1.4em;
    color: #606060;
    font-family: 'Open Sans', sans-serif;
   }

   @media  (min-width: 0px)  and (max-width: 800px){

    .parrafoTer{
        margin-top:0vh;
        font-size: 27.5px;
        line-height: 1.6em;
        color: #606060;
        font-family: 'Open Sans', sans-serif;
       }
   
}

   .imgInicio{
       width: 100%;
   }


   @media  (min-width: 0px)  and (max-width: 800px){

    .imgInicio{
        border-radius: 5em;
      
        margin:auto;
        display: block;
    }



    }


   .imgCaruselUno
   {
       background-image: url('https://c0.wallpaperflare.com/preview/944/356/969/concept-construction-page-site.jpg');
       background-size: cover;
       background-repeat: no-repeat;
    }


   .imgCaruselDos
   {
       background-image: url('https://c0.wallpaperflare.com/preview/920/519/697/abstract-php-c-analytics.jpg');
       background-size: cover;
       width: 100%;
       background-repeat: no-repeat;
    }

  

    @media only screen and (min-width: 993px){

.container {
    width: 85%;
}
}



.espacioSuperiorDiv{
    padding-top: 117px;
}
.espacioInferiorDiv{
    padding-bottom: 80px;
}


.tituloProyecto
{
    color: #464646;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    margin: 15px 0 10px 0;
}
.margenCero
{
    margin:0 !important;
}

/* texto alineado normalmente */

.textoLeft{

    text-align: left;
}
.textoH4
{

    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;
}


.margenCuarenta{
    margin-top: 40px;
    margin-bottom: 40px;
}

.pt-button{

    padding: 20px 0;
}

.boton{
    padding: 8px 18px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
}

.btn-style-2 {
    background: #fff;
    border-color: #fff;
    color: #444;
}

.margenCien{
    margin-top: 100px;
    opacity: 0.4;
    width:70%;
    border-top: 1px solid #dcdcdc;
}
.caja{

    height: 200px;
}
.cajaPerfil{
    padding: 0px 0px !important;
}


@media only screen and (min-width: 394px){
    .cajaPerfil{
        padding: 0px 0px !important;
    }
}
.team-name{
text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6em;
    margin: 14px 0 5px 0;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
}

p.team-job {
    color: #707070;
    margin: 0 0 14px 0;
    text-align: center;
}

.list-inline>li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
    text-align: center;
}
.team{
    text-align: center;
}



.botonEnviar
{
    padding: 8px 18px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 0;
    box-shadow: none;
    background: #fff;
    border: 1px solid #444;
    color: #444;
    float: left;
    cursor: pointer;
}

.botonEnviar:hover
{
    padding: 8px 18px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 0;
    box-shadow: none;
    background: #444;
    border: 1px solid #fff;
    color: #fff;
    float: left;
    cursor: pointer;
}
.iconoContacto {
    margin-right: 8px;
}


.font14 a{
    font-size: 18px;
    color: white;
}



@media only screen and (min-width: 993px){

    .pruebaContacto .container {
        width: 95%;
    }
}


.copyright {
    text-align: center;
    color: #858585;
    font-size: 13px;
    margin: 50px 0 30px 0;
}

.copyright hr {
    opacity: 0.3;
    border-top: 1px solid #dcdcdc;
}

.marginTop20{

    margin-top: 20px;
}
.bottomCero{

    margin-bottom: 0px !important;
}

.texto
{
    font-size: 59px;
}
h1
{
    line-height: 139% !important;
}

@media only screen and (min-width: 850px){

    .texto
{
    font-size: 59px;
}
}


.navbar-fixed {
    position: absolute;
    z-index: 967;
    top: 0px;
}


.navegadorResponsive-close{height: 50px;overflow: hidden;
    transition-duration: 0.5s;

}




.navegadorResponsive-open{height: 280px;overflow: hidden;
   
  transition-duration: 0.5s;
}

@media only screen and (min-width: 602px){

    .navegadorResponsive-open{height: 380px;overflow: hidden;
   
        transition-duration: 0.5s;
      }
      
    
    }



nav ul li {
    transition: background-color .3s;
    float: none;
    padding: 0;
}
.right {
    float: left !important;
}

nav ul {
    margin: 0px 0 0 0;
    text-align: center;
    width: 100%;
}

.hide-on-med-and-down {
    display: block !important;
}



@media only screen and (min-width: 992px){


    nav ul li {
      transition: background-color .3s;
      float: right;
      padding: 0;
    }
    }




    .single{

        background:rgba(0,0,0,0.58);
            
        color:white;
        width: 300px;
        border-radius: 5px;
        margin-left:50px
    }
    .singledos{

        margin:100px 24vw;
    }

    .single p{

        margin:0
    }

    .precioTitulo
    {
        font-size: 30px!important;
    }


@media (max-width:600px){

    nav .brand-logo {
        position: absolute;
        color: #fff;
        display: inline-block;
        font-size: 19px;
        padding: 0;
    }
}

.nav_relative{
    position:relative;
    height: 100%;
}

